.IntroPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../images/page1-background.png);
  background-size: cover;
  background-position: center center;
  //- 媒体查询(1-760px)
  @media screen and (max-width: 760px) {
    min-height: 100vh;
    .bodyText {
      font-size: 0.56rem;
      font-weight: 600;
      color: #fff;
      padding: 0 1.05rem;
      text-align: center;
      line-height: 0.78rem;
    }
    .bodyTags {
      font-size: 0.24rem;
      line-height: 0.33rem;
      color: #fff;
      padding: 0.5rem 0;
      text-align: center;
    }
    .bodySlider {
      font-family: PingFangSC-Semibold, PingFang SC;
      margin-top: 0.3rem;
      color: #fff;
      font-size: 0.24rem;
      font-weight: 600;
      line-height: 0.33rem;
      text-align: center;
      -webkit-animation: hover_slider infinite 1s linear;
      animation: hover_slider infinite 1s linear;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    .bodyFooter {
      display: none;
    }
  }
  //- 媒体查询(761px-1440px)
  @media screen and (min-width: 761px) and(max-width: 1440px) {
    padding-top: 70px;
    min-height: calc(100vh - 70px);
    .bodyText {
      font-size: 43px;
      font-weight: 600;
      color: #fff;
      padding: 0 200px;
      text-align: center;
      line-height: 56px;
      max-width: 550px;
    }
    .bodyTags {
      font-size: 18px;
      color: #fff;
      padding: 25px 0;
      text-align: center;
    }
    .bodySlider {
      margin-top: 20px;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      -webkit-animation: hover_slider infinite 1s linear;
      animation: hover_slider infinite 1s linear;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    .bodyFooter {
      font-size: 12px;
      display: block;
      color: #fff;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  //- 媒体查询(>1440px)
  @media screen and (min-width: 1441px) {
    padding-top: 70px;
    min-height: calc(100vh - 70px);
    .bodyText {
      font-size: 62px;
      font-weight: 600;
      color: #fff;
      padding: 0 200px;
      text-align: center;
      line-height: 84px;
      max-width: 850px;
    }
    .bodyTags {
      font-size: 24px;
      color: #fff;
      padding: 60px 0;
      text-align: center;
    }
    .bodySlider {
      margin-top: 40px;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      -webkit-animation: hover_slider infinite 1s linear;
      animation: hover_slider infinite 1s linear;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    .bodyFooter {
      font-size: 12px;
      display: block;
      color: #fff;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
  @-webkit-keyframes hover_slider {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    33% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
    }
    66% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    100% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
    }
  }
  @keyframes hover_slider {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    33% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
    }
    66% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    100% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
    }
  }
}
